<template>
  <div class="row m-0 align-items-center justify-content-center relative">
    <div class="col-12 col-md-auto">
      <div class="row justify-content-md-center align-items-center">
        <div class="col-12 col-md-auto mb-4 mb-md-0">
          <div class="row h-100 align-items-center justify-content-center">
            <div class="col-auto col-md medium green-text text-end line1">
              FILTER<div class="d-none d-md-block">APPEALS</div><span class="d-inline-block d-md-none">&nbsp;APPEALS</span>
            </div>
            <div class="col-auto col-md-auto p-0">
              <IconFilter color="green" size="size28" class="d-none d-md-block" />
              <IconFilter color="green" size="size16" class="d-md-none" />
            </div>
          </div>
        </div>
        <div class="col-6 col-md-auto px-1" v-if="projectCategories.size > 0">
          <FilterSelect
            id="category"
            :options="projectCategories"
            placeholder="Appeal Category"
            @applyfilter="filterProjectCategory"
            @clearfilter="filterProjectCategoryClear"
            title="Filter Appeal Category"
          />
        </div>
        <div class="col-6 col-md-auto px-1" v-if="appealTypes.size > 0">
          <FilterSelectAppeal
            v-model="appealTypeValue"
            id="appealType"
            :options="appealTypes"
            placeholder="Appeal Type"
            @applyfilter="filterAppealType"
            @clearfilter="filterAppealTypeClear"
            title="Filter Appeal Type"
          />
        </div>
        <div class="col-6 col-md-auto px-1" v-if="projectCountries.size > 0">
          <FilterSelect
            id="country"
            :options="projectCountries"
            placeholder="Country"
            @applyfilter="filterCountry"
            @clearfilter="filterCountryClear"
            title="Filter Country"
          />
        </div>
        <div class="col-6 col-md-auto px-1"  v-if="donationType.size > 0">
          <FilterSelect
            id="donationType"
            :options="donationType"
            placeholder="Donation Type"
            @applyfilter="filterDonationType"
            @clearfilter="filterDonationTypeClear"
            title="Filter Donation Type"
          />
        </div>
        <div class="col-6 col-md-auto px-1" v-if="priceRange.size > 0">
          <FilterSelect
            id="price"
            :options="priceRange"
            placeholder="Price"
            @applyfilter="filterPrice"
            @clearfilter="filterPriceClear"
            title="Filter Price"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    FilterSelectAppeal: defineAsyncComponent(() => import('./FilterSelectAppeal.vue')),
    FilterSelect: defineAsyncComponent(() => import('@/components/FilterSelect.vue')),
    IconFilter: defineAsyncComponent(() => import('@/components/icons/IconFilter.vue'))
  },
  name: 'AppealFilter',
  props: ['filteredProjects', 'appealType'],
  emits: ['update:appealType', 'updatePrice'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  },
  computed: {
    projectCountries () {
      const result = new Map()
      this.filteredProjects.forEach(el => {
        const item = result.get(el.location.country.id) || { id: el.location.country.id, name: el.location.country.name, count: 0 }
        item.count++
        result.set(item.id, item)
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      return sortedAsc
    },
    projectCategories () {
      const result = new Map()
      this.filteredProjects.forEach(el => {
        const item = result.get(el.projectType.id) || { id: el.projectType.id, name: el.projectType.name, count: 0 }
        item.count++
        result.set(item.id, item)
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      return sortedAsc
    },
    donationType () {
      const result = new Map()
      this.filteredProjects.forEach(el => {
        if (el.projectDonationTypeMaps) {
          el.projectDonationTypeMaps.forEach(donation => {
            const item = result.get(donation.donationType.donationTypeID) || { id: donation.donationType.donationTypeID, name: donation.donationType.description, count: 0 }
            item.count++
            result.set(item.id, item)
          })
        }
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      return sortedAsc
    },
    appealTypeValue: {
      get () {
        return this.appealType
      },
      set (val) {
        this.$emit('update:appealType', val)
      }
    },
    appealTypes  () {
      const result = new Map()
      this.filteredProjects.forEach(el => {
        if (el.esaaleSawaab) {
          const item = result.get('esaaleSawaab') || { id: 'esaaleSawaab', name: 'Esaal-e-Sawaab', count: 0 }
          item.count++
          result.set(item.id, item)
        }
        if (el.giftOptions) {
          const item = result.get('giftOptions') || { id: 'giftOptions', name: 'Gift Options', count: 0 }
          item.count++
          result.set(item.id, item)
        }
        if (el.sadaqahJaariyah) {
          const item = result.get('sadaqahJaariyah') || { id: 'sadaqahJaariyah', name: 'Sadaqah Jaariyah', count: 0 }
          item.count++
          result.set(item.id, item)
        }
        if (el.section18ATAX) {
          const item = result.get('section18ATAX') || { id: 'section18ATAX', name: 'Section 18A', count: 0 }
          item.count++
          result.set(item.id, item)
        }
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      return sortedAsc
    },
    priceRange () {
      const result = new Map()
      this.filteredProjects.forEach(el => {
        if (el.cost) {
          if (el.cost < 50) {
            const id = '0-50'
            const item = result.get(id) || { id, name: 'Up to ' + this.defaultCurrency + '50', count: 0, lowerCost: 0 }
            item.count++
            result.set(item.id, item)
          } else if (el.cost < 150 && el.cost >= 50) {
            const id = '50-150'
            const item = result.get(id) || { id, name: this.defaultCurrency + '50 to ' + this.defaultCurrency + '150', count: 0, lowerCost: 50 }
            item.count++
            result.set(item.id, item)
          } else if (el.cost < 500 && el.cost >= 150) {
            const id = '150-500'
            const item = result.get(id) || { id, name: this.defaultCurrency + '150 to ' + this.defaultCurrency + '500', count: 0, lowerCost: 150 }
            item.count++
            result.set(item.id, item)
          } else if (el.cost < 1000 && el.cost >= 500) {
            const id = '500-1000'
            const item = result.get(id) || { id, name: this.defaultCurrency + '500 to ' + this.defaultCurrency + '1000', count: 0, lowerCost: 500 }
            item.count++
            result.set(item.id, item)
          } else if (el.cost >= 1000) {
            const id = '1000+'
            const item = result.get(id) || { id, name: '' + this.defaultCurrency + '1000 & above', count: 0, lowerCost: 1000 }
            item.count++
            result.set(item.id, item)
          }
        }
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].lowerCost > b[1].lowerCost ? 1 : -1)))

      return sortedAsc
    }
  },
  methods: {
    filterCountry (val) {
      if (val.length > 0) {
        this.$emit('updateCountry', val)
      } else {
        this.$emit('updateCountry', null)
      }
    },
    filterCountryClear () {
      this.$emit('updateCountry', null)
    },
    filterProjectCategory (val) {
      if (val.length > 0) {
        this.$emit('updateCategory', val)
      } else {
        this.$emit('updateCategory', null)
      }
    },
    filterProjectCategoryClear () {
      this.$emit('updateCategory', null)
    },
    filterPrice (val) {
      if (val.length > 0) {
        this.$emit('updatePrice', val)
      } else {
        this.$emit('updatePrice', null)
      }
    },
    filterPriceClear () {
      this.$emit('updatePrice', null)
    },
    filterAppealType (val) {
      this.appealTypeValue = val
    },
    filterAppealTypeClear () {
      this.appealTypeValue = null
    },
    filterDonationType (val) {
      if (val.length > 0) {
        this.$emit('updateDonationType', val)
      } else {
        this.$emit('updateDonationType', null)
      }
    },
    filterDonationTypeClear () {
      this.$emit('updateDonationType', null)
    }
  }
}
</script>

<style scoped>
.line1 {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--green-color);
  line-height: 1;
}
</style>
